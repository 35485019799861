import { useHover, useLayer } from 'react-laag';
import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { PlacementType } from 'react-laag/dist/PlacementType';

// TODO unify with the Tooltip component

export default function StickyTooltip({
  children,
  tooltipContent,
  placement,
}: {
  children: ReactElement;
  tooltipContent: JSX.Element;
  placement: PlacementType;
}) {
  // We use `useHover()` to determine whether we should show the tooltip.
  // Notice how we're configuring a small delay on enter / leave.
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });

  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement,
    triggerOffset: 15, // small gap between wrapped content and the tooltip
  });

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  let trigger;
  if (isReactText(children)) {
    trigger = (
      <span className="tooltip-text-wrapper" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  } else {
    // In case of a react-element, we need to clone it in order to attach our own props
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  }

  // We're using framer-motion for our enter / exit animations.
  // This is why we need to wrap our actual tooltip inside `<AnimatePresence />`.
  // The only thing left is to describe which styles we would like to animate.
  return (
    <>
      {trigger}
      {renderLayer(
        <div>
          {isOver && (
            <motion.div
              className="tooltip-box"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              {tooltipContent}
            </motion.div>
          )}
        </div>
      )}
    </>
  );
}

function isReactText(children) {
  return ['string', 'number'].includes(typeof children);
}
