import type { AppProps } from 'next/app';
import Layout from '@/layout/Layout';
import { NextPage } from 'next';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { FirebaseProvider } from '@/core/firebase';
import { RecoilRoot, useRecoilValue, useResetRecoilState } from 'recoil';
import { useHttpInterceptor } from '@/core/http';
import { useSyncUser } from '@/core/user/useSyncUser';
import { ErrorBoundary } from '@/core/errorBoundary';
import ErrorModal from '@/features/errorAlert/ErrorModal';
import { errorState } from '@/stores/errorStore';
import { useRedirectToHome } from '@/core/redirect/useRedirectToHome';
import { authState } from '@/stores/authStore';
import { useQueryPrice } from '@/features/payment';
import OneTrustScript from '@/core/oneTrust/OneTrustScript';
import { hotjar } from 'react-hotjar';
import { userAcceptedAnalyticsTracking } from '@/core/helpers/consentHelper';

const _HOTJAR_SNIPPET_VERSION = 6;
const _HOTJAR_SITE_ID = 3525893;

const RecoilRootPatched = RecoilRoot as FC<{ children: ReactElement }>;

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, isLoggedIn: boolean) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function commonLayout(page: ReactElement, isLoggedIn: boolean) {
  if (!isLoggedIn) return null;
  return <Layout>{page}</Layout>;
}

export function WithRecoilAndServices({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || commonLayout;

  const error = useRecoilValue(errorState);
  const resetError = useResetRecoilState(errorState);
  const isLoggedIn = useRecoilValue(authState);

  useHttpInterceptor();
  useSyncUser();
  useRedirectToHome();
  useQueryPrice();

  useEffect(() => {
    if (userAcceptedAnalyticsTracking()) {
      hotjar.initialize(_HOTJAR_SITE_ID, _HOTJAR_SNIPPET_VERSION);
    }
  }, []);

  return (
    <FirebaseProvider>
      <OneTrustScript />
      {getLayout(<Component key={router.route} {...pageProps} />, isLoggedIn)}
      <ErrorModal
        id="error-modal"
        isOpen={error.shouldOpenModal}
        onClose={() => {
          if (error.onClose) {
            error.onClose();
          }
          resetError();
        }}
        title={error.message}
      />
    </FirebaseProvider>
  );
}

export default function AppWrapper({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout) {
  return (
    <RecoilRootPatched>
      <ErrorBoundary>
        <WithRecoilAndServices
          Component={Component}
          pageProps={pageProps}
          router={router}
        />
      </ErrorBoundary>
    </RecoilRootPatched>
  );
}
