interface HelpPageLinkProps {
  className?: string;
}

export default function HelpPageLink({ className }: HelpPageLinkProps) {
  return (
    <a
      target="_blank"
      href="https://remini-web.zendesk.com/hc/en-us/categories/14512607903761-API-Remini-for-Developers"
      rel="noreferrer"
      className={`contrast-high M16 ${className}`}
    >
      Help
    </a>
  );
}
