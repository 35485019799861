import axios, { AxiosError } from 'axios';

const baseDomain = `https://${process.env.NEXT_PUBLIC_BACKEND_DOMAIN}`;
const instance = axios.create({
  baseURL: `${baseDomain}/api`,
  withCredentials: true,
});

export const api = instance;

export type GenericAxiosError<ResponseData = any> = AxiosError<ResponseData>;
