import React from 'react';
import SideMenuButton from '@/layout/sideMenu/SideMenuButton';
import { useRouter } from 'next/router';

export default function SideMenu() {
  const router = useRouter();

  return (
    <div className="bg-gray-500 rounded-2xl h-full">
      <ul className="px-10 py-5 space-y-6">
        <li>
          <SideMenuButton
            pathname="/balance"
            title="Balance"
            currentPathname={router.pathname}
          />
        </li>
        <li>
          <SideMenuButton
            pathname="/key"
            title="API Key"
            currentPathname={router.pathname}
          />
        </li>
        <li>
          <SideMenuButton
            pathname="/credits"
            title="Buy credits"
            currentPathname={router.pathname}
          />
        </li>
        <li>
          <SideMenuButton
            pathname="/account"
            title="Account"
            currentPathname={router.pathname}
          />
        </li>
        <li>
          <SideMenuButton
            title="Documentation"
            externalUrl={`https://${process.env.NEXT_PUBLIC_BACKEND_DOMAIN}/api/`}
            currentPathname={router.pathname}
          />
        </li>
      </ul>
    </div>
  );
}
