import { atom } from 'recoil';

interface APIKeyDetails {
  apiKey: string;
  createdAt: Date;
}

export interface User {
  userId: string;
  email: string;
  firebaseUid: string;
  availableCredits: number;
  apiKey: APIKeyDetails;
}

export const userState = atom<User>({
  key: 'user:details',
  default: {
    userId: '',
    email: '',
    firebaseUid: '',
    availableCredits: 0,
    apiKey: {
      apiKey: '',
      createdAt: new Date(),
    },
  },
});
