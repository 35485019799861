import { useSetRecoilState } from 'recoil';
import {
  creditsRedirectState,
  PRICE_ID_TO_CREDITS,
  selectedPriceState,
} from '@/stores/paymentStore';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useQueryPrice() {
  const router = useRouter();
  const setSelectedPriceId = useSetRecoilState(selectedPriceState);
  const setCreditsRedirect = useSetRecoilState(creditsRedirectState);

  useEffect(() => {
    const { priceId } = router.query;

    if (priceId && (priceId as string) in PRICE_ID_TO_CREDITS) {
      setSelectedPriceId(priceId as string);
      setCreditsRedirect(true);
    }
  }, [router.query, setCreditsRedirect, setSelectedPriceId]);
}
