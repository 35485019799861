import { BadgeVariants } from '@/ui/badge/badgeTypes';
import { ReactNode } from 'react';

interface Props {
  variant: BadgeVariants;
  label: string;
  hidden?: boolean;
  className?: string;
  additionalChildren?: ReactNode;
  onClick?: () => void;
}

export default function Badge({
  variant,
  label,
  hidden,
  className,
  additionalChildren,
  onClick,
}: Props) {
  return (
    <div
      role="presentation"
      className={`badge ${variant} ${
        hidden ? 'opacity-0 hidden' : 'opacity-1'
      } ${className} flex items-center ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <p className={`${additionalChildren ? 'mr-1.5' : ''}`}>{label}</p>
      {additionalChildren}
    </div>
  );
}
