import Script from 'next/script';
import React from 'react';

export default function OneTrustScript() {
  return (
    <>
      {/* OneTrust Cookies Consent Notice start for developer.remini.ai */}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_ID}
      />
      {/* OneTrust Cookies Consent Notice end for developer.remini.ai */}
    </>
  );
}
