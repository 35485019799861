import { atom, selector } from 'recoil';

export interface CreditsDetail {
  creditsCount: number;
  totalPrice: number;
  currency: string;
}

export interface IdToCredits {
  [priceId: string]: CreditsDetail;
}

export const PRICE_ID_TO_CREDITS: IdToCredits = {
  v1_100_39: {
    creditsCount: 100,
    totalPrice: 39,
    currency: 'usd',
  },
  v1_300_79: {
    creditsCount: 300,
    totalPrice: 79,
    currency: 'usd',
  },
  v1_1000_199: {
    creditsCount: 1000,
    totalPrice: 199,
    currency: 'usd',
  },
  v1_10000_1399: {
    creditsCount: 10000,
    totalPrice: 1399,
    currency: 'usd',
  },
  v1_30000_2999: {
    creditsCount: 30000,
    totalPrice: 2999,
    currency: 'usd',
  },
};

export const selectedPriceState = atom({
  key: 'price:selectedId',
  default: 'v1_100_39',
});

export const selectedPriceDetailsSelector = selector({
  key: 'price:selectedDetails',
  get: ({ get }) => {
    const selectedPriceId = get(selectedPriceState);
    return PRICE_ID_TO_CREDITS[selectedPriceId];
  },
});

export const creditsRedirectState = atom<boolean>({
  key: 'price:redirect',
  default: false,
});
