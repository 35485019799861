import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '@/stores/userStore';
import useUser from '@/core/user/useUser';
import { authState } from '@/stores/authStore';

export function useSyncUser() {
  const isLoggedIn = useRecoilValue(authState);
  const user = useRecoilValue(userState);

  const { getUser } = useUser();

  useEffect(() => {
    const updateUser = async () => {
      await getUser();
    };

    if (isLoggedIn && user.userId === '') {
      updateUser().catch(console.error);
    }
  }, [getUser, isLoggedIn, user.userId]);
}
