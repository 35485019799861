import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { authState } from '@/stores/authStore';

export function useRedirectToHome() {
  const isLoggedIn = useRecoilValue(authState);
  const router = useRouter();

  useEffect(() => {
    const redirectToHome = async () => {
      await router.push({ pathname: '/' });
    };

    if (!isLoggedIn && router.pathname !== '/') {
      redirectToHome().catch(console.error);
    }
  }, [isLoggedIn, router]);
}
