import React, { PropsWithChildren } from 'react';
import Header from '@/layout/header/Header';
import SideMenu from '@/layout/sideMenu/SideMenu';

export default function Layout({ children }: PropsWithChildren) {
  return (
    <div>
      <Header />
      <div className="container-full flex pt-20 pb-12 h-full">
        <SideMenu />
        <div className="w-full">
          <div className="bg-white rounded-2xl ml-10 overflow-auto px-20 py-14">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
