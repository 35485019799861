import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import externalLinkIcon from '@/public/images/icons/externalLink.svg';
import { useRecoilValue } from 'recoil';
import { isBusySelector } from '@/stores/appStore';

interface SideMenuButtonProps {
  pathname?: string;
  externalUrl?: string;
  title: string;
  currentPathname: string;
}

export default function SideMenuButton({
  pathname,
  externalUrl,
  title,
  currentPathname,
}: SideMenuButtonProps) {
  const router = useRouter();
  const isBusy = useRecoilValue(isBusySelector);

  const isExternalLinkButton =
    pathname === undefined && externalUrl !== undefined;
  const isCurrentPath =
    pathname !== undefined && currentPathname.includes(pathname);

  const onClick = useCallback(async () => {
    if (isExternalLinkButton) {
      window.open(externalUrl, '_blank', 'noreferrer');
      return;
    }

    if (isCurrentPath) return;
    await router.push({ pathname });
  }, [externalUrl, isCurrentPath, isExternalLinkButton, pathname, router]);

  return (
    <button
      type="button"
      className={`btn btn--xl btn--sidebar mt-4 ${
        isCurrentPath
          ? 'bg-overlay-white-10'
          : 'hover:bg-overlay-white-10 hover:opacity-80'
      }`}
      onClick={onClick}
      disabled={isBusy}
    >
      {title}
      {isExternalLinkButton && (
        <img {...externalLinkIcon} className="ml-2" alt="external link" />
      )}
    </button>
  );
}
