import { atom } from 'recoil';

export interface Error {
  message: string;
  shouldOpenModal: boolean;
  onClose?: () => void;
}

export const errorState = atom<Error>({
  key: 'error:details',
  default: {
    message: '',
    shouldOpenModal: false,
  },
});
