import ErrorAlert from '@/ui/content/ErrorAlert';

type GenericErrorAlertProps = {
  eventId: string;
};

export function GenericErrorAlert({ eventId }: GenericErrorAlertProps) {
  return (
    <div className="h-full flex">
      <ErrorAlert title="Something went wrong" className="m-auto">
        <div className="space-y-3">
          <p>
            Are you experiencing any issue? Contact us at{' '}
            <a
              className="text-primary-accent-100 underline"
              href="mailto:remini-api@remini.ai"
            >
              remini-api@remini.ai
            </a>
          </p>
          <p>
            <code className="text-primary-accent-100">{eventId}</code>
          </p>
        </div>
      </ErrorAlert>
    </div>
  );
}
