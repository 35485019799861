import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { ReactNode, useCallback, useRef } from 'react';
import useEventListener from '@/core/listeners/useEventListener';

interface ModalProps {
  children: ReactNode;
  className?: string;
  id: string;
  isOpen: boolean;
  onCloseButtonClick: () => void;
}

export default function ModalWrapper({
  children,
  className,
  id,
  isOpen,
  onCloseButtonClick,
}: ModalProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const shouldPreventPop = id.includes('[prevent-pop]');

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !shouldPreventPop) {
        onCloseButtonClick();
      }
    },
    [onCloseButtonClick, shouldPreventPop]
  );
  useEventListener(null, 'keydown', onKeyDown);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <motion.div
      key={id}
      className="fixed inset-0 z-10 bg-overlay-black-70"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <motion.div
        className="absolute overflow-y-auto w-full h-full inset-0"
        onClick={(e) => {
          if (e.target === e.currentTarget && !shouldPreventPop) {
            onCloseButtonClick();
          }
        }}
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        transition={{ duration: 0.2 }}
      >
        <div className="w-full h-full p-4 flex justify-center items-center pointer-events-none">
          <motion.div
            layout
            className={`max-h-full p-5 section-foreground rounded-3xl shadow-md pointer-events-auto ${className}`}
            ref={containerRef}
            role="dialog"
            aria-modal="true"
            aria-label={`modal-${id}`}
          >
            {children}
          </motion.div>
        </div>
      </motion.div>
    </motion.div>,
    document.body
  );
}
