import logo from '@/public/images/logo.svg';
import React from 'react';
import HelpPageLink from '@/layout/header/HelpPageLink';
import { Badge, badgeTypes } from '@/ui/badge';
import purpleFAQIcon from '@/public/images/icons/purpleFAQ.svg';
import { useRouter } from 'next/router';
import { StickyTooltip } from '@/ui/stickyTooltip';
import { useRecoilValue } from 'recoil';
import { userState } from '@/stores/userStore';
import { isBusySelector } from '@/stores/appStore';
import { authState } from '@/stores/authStore';

interface HeaderProps {
  className?: string;
}

export default function Header({ className }: HeaderProps) {
  const router = useRouter();
  const isLoggedIn = useRecoilValue(authState);
  const user = useRecoilValue(userState);

  const isBusy = useRecoilValue(isBusySelector);

  return (
    <header
      className={`container-full fixed flex h-20 justify-between items-center ${className}`}
    >
      <img {...logo} alt="Remini for developers" />
      <div className="flex items-center">
        {isLoggedIn && (
          <Badge
            variant={badgeTypes.BadgeVariants.Purple}
            label={`${user.availableCredits} credits left`}
            className="mr-10"
            additionalChildren={
              <StickyTooltip
                placement="bottom-center"
                tooltipContent={
                  <div className="bg-secondary-yellow text-black rounded-2xl px-4 py-3">
                    <span className="absolute block w-0 h-0 top-0 left-1/2 -translate-y-1/2 -translate-x-2/4 border-l-8 border-r-8 border-transparent border-b-8 border-b-secondary-yellow" />
                    <p className="M13 whitespace-pre-line">
                      Each successful API call{'\n'}
                      <strong>costs 1 credit</strong>
                    </p>
                  </div>
                }
              >
                <img {...purpleFAQIcon} alt="help" />
              </StickyTooltip>
            }
            onClick={async () => {
              if (isBusy) return;
              await router.push({ pathname: '/balance' });
            }}
          />
        )}
        <HelpPageLink />
      </div>
    </header>
  );
}
