import { atom } from 'recoil';
import { safeLocalStorage } from '@/core/storage/safeLocalStorage';
import whenInBrowser from '../core/environment/whenInBrowser';

const _SESSION_EXPIRE_KEY = 'user:sessionExpire';
const _SESSION_TTL = 7 * 24 * 60 * 60 * 1000; // 7 days

interface SessionExpire {
  expiry: number;
}

export const hasAuthSessionExpired = (): boolean => {
  const persistedSessionStr = safeLocalStorage.getItem(_SESSION_EXPIRE_KEY);
  if (!persistedSessionStr) {
    return true;
  }

  const persistedSession = JSON.parse(persistedSessionStr) as SessionExpire;
  const now = new Date();
  return now.getTime() > persistedSession.expiry;
};

function setInitialAuthenticationState({ setSelf }) {
  if (hasAuthSessionExpired()) {
    safeLocalStorage.removeItem(_SESSION_EXPIRE_KEY);
  } else {
    setSelf(true);
  }
}

function storeSessionState({ onSet }) {
  onSet((isAuthenticated: boolean) => {
    if (isAuthenticated) {
      const now = new Date();
      const session: SessionExpire = {
        expiry: now.getTime() + _SESSION_TTL,
      };

      safeLocalStorage.setItem(_SESSION_EXPIRE_KEY, JSON.stringify(session));
    } else {
      safeLocalStorage.removeItem(_SESSION_EXPIRE_KEY);
    }
  });
}

export const authState = atom<boolean>({
  key: 'user:isAuthenticated',
  default: false,
  effects_UNSTABLE: [
    whenInBrowser(setInitialAuthenticationState),
    storeSessionState,
  ],
});
