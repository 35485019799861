import { useCallback } from 'react';
import { api, GenericAxiosError } from '@/core/http';
import { AxiosResponse } from 'axios';
import { useSetRecoilState } from 'recoil';
import { User, userState } from '@/stores/userStore';

const useUser = () => {
  const setUser = useSetRecoilState(userState);

  const getUser = useCallback(async () => {
    const result: AxiosResponse<User, GenericAxiosError> = await api.get(
      '/admin/@me'
    );

    setUser(result.data);
  }, [setUser]);

  return {
    getUser,
  };
};

export default useUser;
