import React from 'react';
import errorIcon from '@/public/images/icons/error.svg';
import ModalWrapper from '@/core/modals/ModalWrapper';

interface ErrorModalProps {
  id: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export default function ErrorModal({
  id,
  isOpen,
  className = '',
  onClose,
  title,
}: ErrorModalProps) {
  return (
    <ModalWrapper
      id={id}
      isOpen={isOpen}
      onCloseButtonClick={onClose}
      className="max-w-[300px] px-10 pb-8"
    >
      <div
        className={`text-center space-y-6 leading-none break-words ${className}`}
      >
        <img className="inline-block" {...errorIcon} alt="" />
        <div className="space-y-3 max-w-xs">
          <h3 className="SB18 text-primary-accent">{title}</h3>
          <div className="M15 w-full flex items-center justify-center">
            <button
              type="button"
              className="btn btn--large btn--error mt-6"
              onClick={onClose}
            >
              Ok, Got It
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
