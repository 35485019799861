import { useEffect } from 'react';
import { api } from '@/core/http/httpService';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { userState } from '@/stores/userStore';
import { authState, hasAuthSessionExpired } from '@/stores/authStore';
import {
  fromCamelCaseToSnakeCaseRecursive,
  fromSnakeCaseToCamelCaseRecursive,
} from '@/core/objectManipulation/objectManipulation';
import { errorState } from '@/stores/errorStore';

export function useHttpInterceptor() {
  const setError = useSetRecoilState(errorState);
  const resetIsLoggedIn = useResetRecoilState(authState);
  const resetUser = useResetRecoilState(userState);

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use(
      (config) => {
        config.data = fromCamelCaseToSnakeCaseRecursive(config.data);
        return config;
      },
      (err) => {
        console.error('Request interceptor failed: ', err);
        return Promise.reject(err);
      }
    );

    return () => api.interceptors.request.eject(requestInterceptor);
  }, []);

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (response) => {
        response.data = fromSnakeCaseToCamelCaseRecursive(response.data);
        return response;
      },
      async (err) => {
        console.error('Response interceptor failed: ', err);

        if (err.response.status === 401) {
          // if the session has expired, the user is automatically redirected to the home page
          if (!hasAuthSessionExpired()) {
            setError({
              message: 'The login token is invalid. Please log in again.',
              shouldOpenModal: true,
              onClose: () => {
                resetIsLoggedIn();
                resetUser();
              },
            });
          }

          return;
        }

        setError({
          message: err.response.data.error_description || err.message,
          shouldOpenModal: true,
        });

        return Promise.reject(fromSnakeCaseToCamelCaseRecursive(err));
      }
    );

    return () => api.interceptors.response.eject(responseInterceptor);
  }, [resetIsLoggedIn, resetUser, setError]);
}
