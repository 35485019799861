import { safeLocalStorage } from '@/core/storage/safeLocalStorage';

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function createCookie(name: string, value: string | number | null) {
  const twoYears = 365 * 2;
  const date = addDays(new Date(), twoYears);
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value}${expires}; path=/`;
  safeLocalStorage.setItem(`${name}_exp`, date.toISOString());
}

export function readCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function readCookieWithPrefix(prefix: string) {
  const ca = document.cookie.split(';');
  return ca.reduce((obj, cookie) => {
    const [key, value] = cookie.split('=');
    if (key.trim().startsWith(prefix)) obj[key.trim()] = value;
    return obj;
  }, {});
}

export function eraseCookie(name: string) {
  createCookie(name, '');
}
